<template>
  <section>
    <v-row>
      <v-col class="pb-0" cols="12" sm="6">
        <p class="mb-0 mt-2 grey--text">{{$_strings.order.SHIPPER_NAME}}</p>
        <v-text-field
          outlined
          hide-details
          dense
          class="caption"
          disabled
          :value="shipment.shipperName">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <p class="mb-0 mt-2 grey--text">{{$_strings.order.CATEGORY}}</p>
        <v-text-field
          outlined
          hide-details
          dense
          class="caption"
          disabled
          :value="shipment.category">
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6">
        <p class="mb-0 mt-2 grey--text">{{$_strings.order.SUB_CATEGORY}}</p>
        <v-text-field
          outlined
          hide-details
          dense
          class="caption"
          disabled
          :value="shipment.subCategory">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <p class="mb-0 mt-2 grey--text">{{$_strings.order.GOODS_NOTE}}</p>
        <p class="ma-0" v-html="handleShowNotes(shipment.goodsNotes)"></p>
      </v-col>
      <v-col cols="12" sm="6">
        <p class="mb-0 mt-2 grey--text">{{$_strings.order.ADDITIONAL_NOTE}}</p>
        <p class="ma-0" v-html="handleShowNotes(shipment.additionalNote)"></p>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleShowNotes(notes) {
      if (!notes) return '-';
      let stringNotes = JSON.stringify(notes);
      stringNotes = stringNotes.replace(/^"|"$/g, '');
      stringNotes = stringNotes.replace(/\\n/g, '<br>');
      return stringNotes;
    },
  },
};
</script>
